import React, { useContext, useEffect, useState } from 'react';
import { Container, Step, StepLabel, Stepper, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import FormPasswordPage from './FormPasswordPage';
import FormPage from './FormPage';
import { useFetchForm } from '../../hooks/useFetchForm';
import Loader from '../../components/Loader';
import { FormProvider, useForm } from 'react-hook-form';
import { useFetchPublicWorkspaceResource } from '../../hooks/useFetchPublicWorkspaceResource';
import {
  getAllLanguagePacksLanguages,
  getLanguagePackByLanguage,
  getLanguagePackLanguage,
} from '../../utils/formUtils';
import { getExcerptFromMessageContent } from '../../utils/reportUtils';
import { ApiContext } from '../../contexts/ApiContext';
import {
  answersToMessageContent,
  getAnswers,
  getFormTemplate,
  getPageTemplate,
  getTranslations,
} from '../../components/FormBlock/utils';
import { getNotificationLanguage } from '../../utils/notificationUtils';
import FormViewToolbar from './FormViewToolbar';
import { useFetchPublicOrganizationResource } from '../../hooks/useFetchPublicOrganizationResource';
import { isEnterpriseOrganization } from '../../utils/modelUtils';
import { OrganizationKeyContext } from '../../contexts/OrganizationKeyContext';
import NotFoundView from '../errors/NotFoundView';
import { getWindowUrl } from '../../utils/appUtils';
import FormCaptcha from './FormCaptcha';
import { useFetchLanguagePacks } from '../../hooks/useFetchLanguagePacks';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet-async';

const RootContainer = styled(Container, { name: 'FormView' })(({ theme }) => ({
  padding: theme.spacing(0, 2, 2, 2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 4, 4, 4),
  },
}));

const FormView = () => {
  const { t, i18n } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const navigate = useNavigate();
  const [captchaSuccess, setCaptchaSuccess] = useState(null);
  const [loginSuccess, setLoginSuccess] = useState(null);
  const { pathname } = useLocation();
  const canonicalUrl = `https://www.safetalk.io${pathname}`;
  const windowUrl = getWindowUrl();
  const { form, notFound, loaded: formLoaded } = useFetchForm(windowUrl);
  const { languagePacks, loaded: languagePacksLoaded } = useFetchLanguagePacks(
    captchaSuccess && loginSuccess ? form?.pk : null,
  );
  const { workspace, loaded: workspaceLoaded } =
    useFetchPublicWorkspaceResource(form?.workspaceKey);
  const { organization, loaded: organizationLoaded } =
    useFetchPublicOrganizationResource(form?.organizationKey);
  const [pageIndex, setPageIndex] = useState(0);
  const [busy, setBusy] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const formMethods = useForm({
    defaultValues: {
      pageTemplate: {},
      translations: {},
      formTemplate: {},
      answers: {},
      reportData: {},
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (form) {
      setLoginSuccess(!form.hasPassword);
      setCaptchaSuccess(!form.captchaEnabled);
    }
  }, [formLoaded]);

  useEffect(() => {
    if (languagePacks) {
      let activeLanguagePack = getLanguagePackByLanguage(
        languagePacks,
        language,
      );
      if (!activeLanguagePack) {
        // Language pack is null if there is no language pack for the i18m language.
        activeLanguagePack = languagePacks[0];
        setLanguage(getLanguagePackLanguage(activeLanguagePack));
      }

      formMethods.reset({
        pageTemplate: form.template.pages[pageIndex],
        translations: activeLanguagePack.content,
        formTemplate: form.template,
        answers: {},
        reportData: {},
      });
    }
  }, [languagePacksLoaded]);

  const submitReport = async (data) => {
    setBusy(true);

    const content = answersToMessageContent(
      data,
      formMethods.formState.defaultValues,
    );
    const excerpt = getExcerptFromMessageContent(content);
    const resourcePassword = await mutationApi.createReport(
      form.organizationKey,
      form.workspaceKey,
      'FORM',
      content,
      excerpt,
      language,
      isEnterpriseOrganization(organization),
    );

    formMethods.reset({
      pageTemplate: form.template.pages[pageIndex + 1],
      translations: getTranslations(formMethods.getValues),
      formTemplate: getFormTemplate(formMethods.getValues),
      answers: {},
      reportData: {
        reportKey: resourcePassword.pk,
        createdAt: resourcePassword.createdAt,
        password: resourcePassword.password,
      },
    });
    setBusy(false);
    setPageIndex((prev) => prev + 1);
  };

  const submitReportConfirmation = async (data) => {
    const { reportKey, createdAt, password, email } = data.reportData;

    if (email) {
      const notificationLanguage = getNotificationLanguage(workspace);
      const subject = t('reportFrom', {
        lng: notificationLanguage,
        value: new Date(createdAt),
      });
      await mutationApi.createEmailConversation(
        reportKey,
        'reportingPerson',
        form.organizationKey,
        form.workspaceKey,
        email,
        'MESSAGES',
        { subject: subject },
      );
    }

    // Redirect to login dialog after clicking on continue in the confirmation dialog
    navigate('/followup');
  };

  const handlePageContinueClick = async (event) => {
    const valid = await formMethods.trigger();
    if (!valid) {
      event.preventDefault();
      return;
    }

    if (pageIndex < form.template.pages.length - 2) {
      setPageIndex((prev) => prev + 1);
      formMethods.reset(
        {
          pageTemplate: form.template.pages[pageIndex + 1],
          translations: getTranslations(formMethods.getValues),
          formTemplate: getFormTemplate(formMethods.getValues),
          answers: getAnswers(formMethods.getValues),
        },
        { keepDirtyValues: true, keepErrors: true },
      );
    }
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    formMethods.reset(
      {
        pageTemplate: getPageTemplate(formMethods.getValues),
        translations: getLanguagePackByLanguage(languagePacks, newLanguage)
          .content,
        formTemplate: getFormTemplate(formMethods.getValues),
      },
      { keepDirtyValues: true, keepErrors: true },
    );
  };

  const handlePageBackClick = () => {
    setPageIndex((prev) => prev - 1);
    formMethods.reset({
      pageTemplate: form.template.pages[pageIndex - 1],
      translations: getTranslations(formMethods.getValues),
      formTemplate: getFormTemplate(formMethods.getValues),
      answers: getAnswers(formMethods.getValues),
    });
  };

  if (notFound || form?.disabled || workspace?.isDeleted) {
    return <NotFoundView />;
  }

  if (form?.captchaEnabled && !captchaSuccess) {
    return (
      <Page component={'main'}>
        <FormCaptcha onSuccess={() => setCaptchaSuccess(true)} />
      </Page>
    );
  }

  if (
    !organizationLoaded ||
    !workspaceLoaded ||
    !formLoaded ||
    !languagePacksLoaded
  ) {
    return (
      <Page
        component="main"
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </Page>
    );
  }

  // TODO: Don't use index as key for Step
  return (
    <Page component={'main'}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <OrganizationKeyContext.Provider value={organization.pk}>
        <RootContainer maxWidth="sm">
          <FormViewToolbar
            language={language}
            supportedLanguages={getAllLanguagePacksLanguages(languagePacks)}
            onLanguageChange={handleLanguageChange}
          />
          <Toolbar>
            {form.template.pages.length > 2 && (
              <Stepper
                activeStep={pageIndex}
                sx={{
                  width: '100%',
                  overflow: 'auto',
                  ...((!loginSuccess || !captchaSuccess) && {
                    visibility: 'hidden',
                  }),
                }}
              >
                {[...Array(form.template.pages.length)].map((_, index) => (
                  <Step key={index}>
                    <StepLabel />
                  </Step>
                ))}
              </Stepper>
            )}
          </Toolbar>
          {!loginSuccess}
          {form.hasPassword && !loginSuccess ? (
            <FormPasswordPage
              workspace={workspace}
              language={language}
              onLoginAccepted={() => setLoginSuccess(true)}
            />
          ) : (
            <FormProvider {...formMethods}>
              <form
                onSubmit={formMethods.handleSubmit(
                  pageIndex === form.template.pages.length - 1
                    ? submitReportConfirmation
                    : submitReport,
                )}
              >
                <FormPage
                  workspace={workspace}
                  language={language}
                  pageIndex={pageIndex}
                  pageCount={form.template.pages.length}
                  busy={busy}
                  onContinueClick={handlePageContinueClick}
                  onBackClick={handlePageBackClick}
                />
              </form>
            </FormProvider>
          )}
        </RootContainer>
      </OrganizationKeyContext.Provider>
    </Page>
  );
};

export default FormView;
